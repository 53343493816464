import { createContext, useContext, useState } from 'react';

const FilterDataContext = createContext(undefined);

export function FilterDataContextWrapper({ children }: any) {
  const [quickInfo, setQuickInfo] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [distance, setDistance] = useState(15);
  const setQuickInfos = (value: any) => {
    setQuickInfo(value);
  };
  function sortData(data: any) {
    setSortBy(data);
  }
  function distanceData(data: any) {
    setDistance(data);
  }

  const sharedState = {
    sortBy,
    sortData,
    quickInfo,
    setQuickInfos,
    distance,
    distanceData,
  };

  return (
    <FilterDataContext.Provider value={sharedState}>
      {children}
    </FilterDataContext.Provider>
  );
}

export function useAppContext() {
  return useContext(FilterDataContext);
}

export default FilterDataContext;
