import { FormattedMessage } from 'react-intl';

export const replaceWithRequiredSiteInfo = (
  brand: string,
  brandUrl: any,
  str: any
) => {
  const brandReplace = str?.replace(/{{Site_Title}}/g, brand);
  return brandReplace?.replace(/{{Site_URL}}/g, brandUrl);
};

export const replaceWithRequiredUrl = (brand: string, str: any) => {
  return str?.replace(/{{Site_URL}}/g, brand);
};

export const capitalizeFirstLetter = (city: any) => {
  const cityName = city && city.charAt(0).toUpperCase() + city.slice(1);
  return cityName;
};

export const capitalizeFirstLetterOfWords = (city: any) => {
  const splitName = city && city.split('-');
  const cityName =
    splitName &&
    splitName
      .map((word: any) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(' ');
  return cityName;
};

export const blogTile = (key: any) => {
  const titles: any = {
    moving: <FormattedMessage id="blog-moving" />,
    storage: <FormattedMessage id="blog-storage" />,
    renovating: <FormattedMessage id="blog-renovating" />,
    lifestyle: <FormattedMessage id="blog-lifestyle" />,
  };

  return titles[key];
};

export const toSentenceCase = (str: any) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};
